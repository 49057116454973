import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import * as data from '../../assets/static.json';

@Injectable({
  providedIn: 'root'
})

export class WebApiHttp {


  public globalurl: string = data.test;

  public ApiURLArray: any = {

    // User URL Start

    login: '/api/User/Login',
    Logout: '/api/User/Logout',
    createUser: '/api/User/CreateUser',
    roleProcess: '/api/Role/RoleProcess',
    signalRNotification: '/Notification',
    locationlist: '/api/User/LocationList',

    // User URL End

    // GateEntry URL Start

    AllGateEntryList: '/api/GateEntry/AllGateEntryList?location=',
    GateEntryInfoByid: '/api/GateEntry/GateEntryInfoByid?gateentry_no=',
    CreateGateEntry: '/api/GateEntry/CreateGateEntry',

    // GateEntry URL End

    // GRN URL Stat

    GRNactivedocumentno: '/api/GRN/GrnActiveDocumentNo',
    DocumentInfoForGRN: '/api/GRN/DocumentInfoForGRN',
    CreateGRNHeader: '/api/GRN/CreateGRNHeader',
    GRNQuantityInWithoutScan: '/api/GRN/GRNQuantityInWithoutScan',
    GRNQuantityInWithScan: '/api/GRN/GRNQuantityInWithScan',
    GateEntryInfoByDocumentNo: '/api/GRN/GateEntryByDocumentNo',
    GRNInfo: '/api/GRN/GRNInfo',
    BarcodeInByPOLineInfo: '/api/GRN/BarcodeInByPOLineInfo',
    DeleteBarcode: '/api/GRN/DeleteScannedBarcode',
    CompleteGRN: '/api/GRN/CompleteGRN',

    // GRN URL end


    // Vendor URL start

    GetVendorDetail: '/api/Vendor/GetVendorDetail?no_or_name=',
    GetAllVendorList: '/api/Vendor/AllVendorList',
    CreateVendor: '/api/Vendor/CreateVendor',
    VendorCatalogueList: '/api/Vendor/VendorCatalogueList',
    VendorCatalogueCreateUpdate: '/api/Vendor/CreateUpdateVendorCatalogue',
    VendorCatalogueDelete: '/api/Vendor/DeleteVendorCatalogue',

    // Vendor URL end

    // Purchase URL start

    ActivePurchaseOrderByVendor: '/api/Purchase/ActivePurchaseOrderByVendor?vendor_no=',
    PurchaseOrderlist: '/api/Purchase/PurchaseOrderlist?location_id=',
    GetVendorCompleteDetailWithPoNo: '/api/Purchase/GetVendorCompleteDetailWithPoNo?vendor_no=',
    GetVendorItem: '/api/Purchase/GetVendorItem',
    PurchaseOrderCreation: '/api/Purchase/PurchaseOrderCreation',
    POForApporoval: '/api/Purchase/POForApporoval?locationid=',
    POApporoved: '/api/Purchase/POApporoved',
    POInfo: '/api/Purchase/POInfo',
    POGRNInfo: '/api/Purchase/POGRNInfo',
    POInfoforUpdate: '/api/Purchase/POInfoForUpdate',

    // Purchase URL end

    // Item URL start

    FindItem: '/api/Item/FindItem?name_or_no=',
    ItemList: '/api/Item/ItemList',
    ItemCategoryList: '/api/Item/ItemCategoryList',
    ItemCategoryCreate: '/api/Item/ItemCategoryCreate',
    ItemSubCategoryList: '/api/Item/ItemSubCategoryList?id=',
    ItemCategoryDelete: '/api/Item/ItemCategoryDelete',
    ItemAttributeTypelIst: '/api/Item/ItemAttributeTypeList',
    ItemAttributeTypeCreate: '/api/Item/ItemAttributeTypeCreate',
    ItemAttributeValueList: '/api/Item/ItemAttributeValueList?attribute_type_no=',
    ItemAttributeValueCreate: '/api/Item/ItemAttributeValueCreate',
    ItemAttributeDelete: '/api/Item/ItemAttributeDelete',
    GetGstGroupId: '/api/Item/GstGroupIdValue',
    GetGstHsnCode: '/api/Item/GstHsnCode?GstGroupId=',
    GetBaseUomValue: '/api/Item/BaseUomValue',
    ItemCreate: '/api/Item/ItemCreate',
    ItemFullInfo: '/api/Item/ItemFullInfo',


    // Item URL end
    //item Sync
    // item_sync: '/pos/api/LocationStore/PullItemDetailFromNAV',
    item_syncNew: '/pos/api/LocationStore/PullItemDetailFromNAVNew',
    //item sync end

    // Bin URL start

    BinList: '/api/Bins/BinList?locationid=',
    BinInfo: '/api/Bins/BinInfo',
    AddBin: '/api/Bins/AddBin',
    DeleteBin: '/api/Bins/DeleteBin',
    BarcodeInBin: '/api/Bins/BarcodeInBin',

    // Bin URL end


    // IQC URL start

    GRNListForIQC: '/api/IQC/GRNListForIQC?locationid=',
    IQCData: '/api/IQC/IQCData',
    GRNScannedBarcodeInfo: '/api/IQC/GRNScannedBarcodeInfo',
    IQCScanBincode: '/api/IQC/IQCBinScan',
    IQCScanBarcode: '/api/IQC/IQCScanBarcode',
    IQCDeleteLine: '/api/IQC/IQCDeleteLine',
    IQCComplete: '/api/IQC/IQCComplete',


    // IQC URL end


    // Putway URL start

    PutwayList: '/api/Putway/PutwayList?locationid=',
    PutwayGRNList: '/api/Putway/PutwayGRNList',
    PutwayHeaderCreate: '/api/Putway/PutwayHeaderCreate',
    PutwayData: '/api/Putway/PutwayData',
    PutwayLineinfo: '/api/Putway/PutwayLineinfo',
    PutwayBarcodeinfo: '/api/Putway/PutwayBarcodeinfo',
    PutwayScanBarcode: '/api/Putway/PutwayScanBarcode',
    PutwayWithoutScanBarcode: '/api/Putway/PutwayWithoutScanBarcode',
    PutwayDeleteLine: '/api/Putway/PutwayDeleteLine',
    PutwayComplete: '/api/Putway/PutwayComplete',

    // Putway URL end

    // Customer URL start

    CreateCustomer: '/api/Customer/CreateCustomer',
    FindCustomer: '/api/Customer/FindCustomer?name=',
    CustomerInfo: '/api/Customer/CustomerInfo?customerid=',
    CustomerList: '/api/Customer/CustomerList',

    // Customer URL end

    // Rejection URL start

    RejectionWork: '/api/Rejection/RejectionWork',

    // Rejection URL end

    // Sale Order URL start

    SaleOrderlist: '/api/Sale/SaleOrderlist',
    GetSaleNo: '/api/Sale/GetSaleNo',
    AddItem: '/api/Sale/AddItem',
    SaleOrderCreation: '/api/Sale/SaleOrderCreation',
    SOForApporoval: '/api/Sale/SOForApporoval?locationid=',
    SOApporoved: '/api/Sale/SOApporoved',
    SaleInfo: '/api/Sale/SaleInfo',
    SaleInvoiceList: '/api/Sale/SaleInvoiceList',
    SaleInvoiceInfo: '/api/Sale/SaleInvoiceInfo',
    get_search_CreditNotDetail: '/pos/api/sale/get_search_CreditNotDetail',
    Sale_get_CreditNotDetail: '/pos/api/Sale/get_CreditNotDetail',
    get_CouponCode_detail: '/pos/api/Sale/get_CouponCode_detail?coupon_code=',
    get_EmpDiscount_detail: '/api/Employee/GetEmployeeByCode?emp_code=',

    // Sale Order URL end

    // Pick Creation URL start

    FindOrder: '/api/PickCreate/FindOrder',
    PickCreation: '/api/PickCreate/PickCreation',

    // Pick Creation URL end


    // dashboard
    DashboardData: '/api/OutBoundDashboard/dashboard_Data?order_type=',
    pos_dash_sale_amount: '/pos/api/Dashboard/dash_sale_amount/',
    pos_dash_weekly_sale_amount: '/pos/api/Dashboard/dash_weekly_sale_amount/',
    WaveWiseZoneActivity: '/api/OutBoundDashboard/WaveWiseZoneActivity?emailid=',
    dash_sale_amount_data_from_to: '/pos/api/Dashboard/dash_sale_amount_data_from_to',



    // shift Api
    allShift: '/api/Shift/AllShift?location_id=',
    allUser: '/api/User/AllUser?name=',
    createShift: '/api/Shift/CreateShift',
    deleteShift: '/api/Shift/DeleteShift',
    updateShift: '/api/Shift/UpdateShift',
    current_shift: '/api/Shift/CurrentShift',
    ManagerDataRoster: '/api/Roster/ManagerData?location_id=',
    // end

    // roaster Api
    createRoster: '/api/Roster/CreateRoster',
    allRoster: '/api/Roster/AllRoster?Email=',
    // end roaster

    // pick admin
    pickZone: '/api/Pick/PickZone',
    PickPriority: '/api/Pick/PickPriority?worktype=',
    PriorityListMaster: '/api/Pick/PriorityList',
    ChangePickPriority: '/api/Pick/ChangePickPriority',
    //

    //  outbound pick

    pickinfo: '/api/Pick/PickerInfo?email=',
    picktrayscan: '/api/Pick/PickStart',
    pickbinbarcodescan: '/api/Pick/ScanBinBarcode',
    picknotfound: '/api/Pick/ProductNotFound',
    pickclosetray: '/api/Pick/CloseTray',
    PickerList: '/api/PickManual/PickerListByPick',
    PickListManual: '/api/PickManual/PickList',
    PickManualInfo: '/api/PickManual/PickManualInfo',
    PickStartManual: '/api/PickManual/PickStart',
    PickScanManual: '/api/PickManual/Scanning',
    PickNFManual: '/api/PickManual/NotFound',
    PickHold: '/api/PickManual/Hold',
    PickUnhold: '/api/PickManual/Unhold',
    PickComplete: '/api/PickManual/Complete',

    // Report Section

    PickInfoReport: '/api/Report/PickInfo?pickno=',
    BinZoneSearchReport: '/api/Report/BinZoneSearch?filter=',
    PickDistribution: '/api/Report/PickDistribution',
    OutboundQualityCheck_reprintReport: '/api/OutboundQualityCheck/Report?order_no=',
    slot_Report: '/api/Report/SlotInfo',
    Slotinfo_report: '/api/Report/SlotSingleReport',
    OqcRangeDetail: '/api/Report/OQCRangeReport',
    ConsolidationRangeDetail: '/api/Report/ConsolidationRangeReport',
    MarketPlace_invoice: '/api/MarketPlace/MarketPlace_invoice?order_no=',
    MarketPlace_invoice_sync: '/api/MarketPlace/MarketPlace_invoice_sync?order_no=',

    // tray master

    AllTray: '/api/Tray/AllTray?location_id=',
    DeleteTray: '/api/Tray/DeleteTray',
    NewTray: '/api/Tray/NewTray',

    // force assignment

    PickInfoForForceAssigment: '/api/Pick/PickInfoForForceAssigment',
    ForceAssigment_submit: '/api/Pick/ForceAssigment',

    // Manifest posting

    SelectShippingOrder: '/api/ManifestPost/shipment_partner',
    CreateManifest: '/api/ManifestPost/manifest_create',
    excel_ReportManifestPost: '/api/ManifestPost/excel_ReportManifestPost?location_id=',
    MarkedCreated: '/api/ManifestPost/manifest_mark_Created',
    MarkedRelease: '/api/ManifestPost/manifest_mark_release',

    // Manifest Handover

    GetPendingShippingManifestNo: '/api/ManifestHandover/get_handover_ship_agent_code?ship_agent_code=',
    getManifestHandoverLine: '/api/ManifestHandover/get_lines_with_ship_agent_code',
    CreateManifestHandover: '/api/ManifestHandover/handover_create',
    Handover_reprint: '/api/ManifestHandover/reprint_handover?handoverno=',

    // Setup

    RoleMasterProcess: '/api/Role/RoleProcess',
    SaleInfoReport: '/api/Report/SaleOrder?source_no=',
    PickSetupAll: '/api/Pick/PickSetupAll',
    RolePermissionDetail: '/api/Role/RolePermissionDetail/',
    RolePermissionUpdate: '/api/Role/RolePermissionUpdate',

    // UserSetup

    GetAllUser: '/api/User/allUser',
    CreateUser: '/api/User/createUser',
    UpdateUser: '/api/User/updateUser',
    GetAllWorktype: '/api/User/Worktype',
    UpdateUserPassword: '/api/User/UpdatePassword',
    AddPrinterIPaddress: '/api/User/AddIPandPort',

    // cage master

    CageList: '/api/Cage/CageList?location_id=',
    cage_ZoneList: '/api/Consolidation/ZoneList?location_id=',
    AddCage: '/api/Cage/AddCage',
    getConfigStyleImages: '/api/BarcodeGenrate/SeachByStyle?stylecode=',
    SeachByStyleAndColor: '/api/BarcodeGenrate/SeachByStyleAndColor?stylecode=',
    PrintBarcodeReport: '/api/BarcodeGenrate/PrintBarcodeReport',


    // tray sorting

    ScanTray: '/api/Sorting/ScanTray',
    ScanCage: '/api/Sorting/ScanCage',

    // DSP

    DSPPartnerList: '/api/DSP/DSPPartnerList?location=',
    CreateUpdateDSP: '/api/DSP/CreateUpdateDSP',
    DSPServiceList: '/api/DSP/DSPServiceList?location=',
    CreateUpdateDSPService: '/api/DSP/CreateUpdateDSPService',
    DspAwb: '/api/DSP/DspAwb',
    UploadAWB: '/api/DSP/UploadAWB',

    // manifest sorting

    ScanAwbNoManifestCreate: '/api/ManifestSorting/ScanAwbNoManifestCreate',

    // reshipment

    AwbList: '/api/Reshipment/AwbList?location=',
    NewDspandAwb: '/api/Reshipment/NewDspandAwb',

    // return manifest

    ReturnManifestList: '/api/ReturnManifest/ReturnManifestList?locationid=',
    CreateReturn: '/api/ReturnManifest/CreateReturn',
    ReturnManifestInfo: '/api/ReturnManifest/ReturnManifestInfo?return_manifest_no=',
    AWBScan: '/api/ReturnManifest/AWBScan',
    Complete: '/api/ReturnManifest/Complete',

    // customer return

    CRList: '/api/CustomerReturn/CRList?location_id=',
    CreateCR: '/api/CustomerReturn/CreateCR',

    // transfer order

    InboundList: '/api/TransferOrder/InboundList',
    AddNewItem: '/api/TransferOrder/AddItem',
    NewTransferNo: '/api/TransferOrder/NewTransferNo',
    CompleteTransfer: '/api/TransferOrder/Complete',
    TransferOrderReport: '/api/TransferOrder/TransferOrderReport?transfer_no=',
    TransferOrderInfo: '/api/TransferOrder/TransferOrderInfo',
    insert_inventory_from_tansfer_order: '/api/TransferOrder/insert_inventory_from_tansfer_order',
    ReceiveInboundList: '/api/TransferOrder/ReceiveInboundList',
    TransferOrderHeaderInsert:'/api/TransferOrder/TransferOrderHeaderInsert',
    TransferOrderHeaderDelete:'/api/TransferOrder/TransferOrderHeaderDelete?document_no=',
    TransferOrderHeaderComplete:'/api/TransferOrder/TransferOrderComplete?document_no=',
    TransferOrderLineInsert:'/api/TransferOrder/TransferOrderLineInsert',
    TransferOrderLineDelete:'/api/TransferOrder/TransferOrderLineDelete',
    TransferOrderGet :'/api/TransferOrder/TransferOrderGet?document_no=',
    // Return GatePass

    RGPList: '/api/ReturnGatePass/RGPList',
    PartyList: '/api/ReturnGatePass/PartyList',
    NewRGPNo: '/api/ReturnGatePass/NewRGPNo',
    AddItemRGP: '/api/ReturnGatePass/AddItem',
    CompleteRGP: '/api/ReturnGatePass/Complete',

    // purchase return order

    PROList: '/api/PurchaseReturnOrder/PROList',
    AddNewPROItem: '/api/PurchaseReturnOrder/AddItem',
    NewPRONo: '/api/PurchaseReturnOrder/NewPRONo',
    CompletePRO: '/api/PurchaseReturnOrder/Complete',
    PROInfo: '/api/PurchaseReturnOrder/PurchaseReturnOrderInfo',

    // consolidation assginment

    PickForConsolidation: '/api/Consolidation/PickForAssignment',
    ZoneList: '/api/Consolidation/ZoneList?email=',
    ZoneAssignment: '/api/Consolidation/ZoneAssignment',

    // consolidation

    HoldSlot: '/api/Consolidation/HoldSlot?email=',
    CageListByZone: '/api/Cage/CageListByZone?zone=',
    RangeChange: '/api/Consolidation/RangeChange',
    ScanBarcode: '/api/Consolidation/ScanBarcode',
    ScanSlot: '/api/Consolidation/ScanSlot',
    BoxChange: '/api/Consolidation/BoxChange',
    GetOrderDetail: '/api/Consolidation/GetOrderDetail?order_no=',

    // oqc

    OQCRangeChange: '/api/OutboundQualityCheck/RangeChange',
    GetSlotForOQC: '/api/OutboundQualityCheck/GetSlotForOQC',
    ReleaseHold: '/api/OutboundQualityCheck/ReleaseHold',
    PostOQC: '/api/OutboundQualityCheck/PostOQC',
    ItemImage: '/api/Item/ItemImage?name_or_no=',


    // adjustment

    DocumentList: '/api/ItemAdjustment/DocumentList',
    DocumentCreate: '/api/ItemAdjustment/DocumentCreate',
    DocumentView: '/api/ItemAdjustment/DocumentView',
    NewLotNo: '/api/ItemAdjustment/NewLotNo',


    // todo POS Apis
    // product
    pos_all_brand: '/pos/api/Brand/all_brand',
    pos_create_Brand: '/pos/api/Brand/create_Brand',
    pos_delete_Brand: '/pos/api/Brand/delete_Brand',
    // end
    // cusstomer
    pos_all_customer: '/pos/api/Customer/all_customer',
    pos_new_customer: '/pos/api/Customer/new_customer',
    pos_find_customer: '/pos/api/Customer/find_customer',
    pos_get_customer_cust_id: '/pos/api/Customer/get_customer_cust_id/',

    // sale process
    pos_item_update: '/pos/api/Sale/SaleLineBulkUpdate',
    pos_check_open_sale: '/pos/api/Sale/check_open_sale',
    UpdateOpenCloseStore: '/pos/api/Sale/UpdateOpenCloseStore?close_store=',
    pos_search_product: '/pos/api/Sale/search_product',
    UpdateCustomerOnSaleHeader: '/pos/api/Sale/UpdateCustomerOnSaleHeader?cust_id=',
    pos_sale_barcode_scan: '/pos/api/Sale/sale_barcode_scan',
    pos_sale_barcode_delete: '/pos/api/Sale/sale_barcode_delete',
    pos_sale_order_post: '/pos/api/Sale/order_post',
    pos_discard_or_park: '/pos/api/Sale/discard_or_park',
    pos_get_all_invoice_sale: '/pos/api/Sale/get_all_invoice_sale',
    get_all_invoice_sale_ho: '/pos/api/Sale/get_all_invoice_sale_ho',
    get_Hold_sale: '/pos/api/Sale/get_Hold_sale',
    get_sale_order_for_hold: '/pos/api/Sale/get_sale_order_for_hold',
    sale_line_price_discount_change: '/pos/api/Sale/sale_line_price_discount_change',
    SaleInvoiceReport: '/pos/api/sale/SaleInvoiceReport?invoice_no=',
    ReturnInvoiceReport: '/pos/api/Return/ReturnInvoiceReport?return_invoice_no=',
    // return order
    return_barcode_delete: '/pos/api/Return/return_barcode_delete',
    delete_reurn_temp_sale_order_data: '/pos/api/Return/delete_reurn_temp_sale_order_data?sale_order_no=',
    pos_return_post: '/pos/api/Return/return_post',
    getSaleRecord: '/pos/api/Return/get_sale_return_order',
    refundItem: '/pos/api/Return/create_return_sale',
    pos_get_sale_details: '/pos/api/Sale/get_sale_details/',
    get_credit_note_header: '/pos/api/Return/get_credit_note_header',
    get_credit_note_detail: '/pos/api/Return/get_credit_note_detail/',
    pos_GetStoreLocationList: '/pos/api/LocationStore/GetStoreLocationList?type=',
    pos_InsertStoreLocationList: '/pos/api/LocationStore/InsertStoreLocationList',
    pos_DeleteStoreLocationList: '/pos/api/LocationStore/DeleteStoreLocationList',

    // price group
    pos_InsertUpdateSelectPriceGroup: '/pos/api/LocationStore/InsertUpdateSelectPriceGroup',
    pos_InsertUpdateSelectDeletePOSTerminalMaster: '/pos/api/LocationStore/InsertUpdateSelectDeletePOSTerminalMaster',
    InsertUpdatePOSPriceMaster: '/pos/api/LocationStore/InsertUpdatePOSPriceMaster',
    GetPOSPriceMaster: '/pos/api/LocationStore/GetPOSPriceMaster',
    //StatementPosting
    StatementPostingGetDateRange:'/api/StatementPosting/StatementPostingGetDateRange',
    StatementCreateHeader:'/api/StatementPosting/StatementCreateHeader',
    StatementPostingDiscardDocument:'/api/StatementPosting/StatementPostingDiscardDocument',
    StatementUpdateLineAmount:'/api/StatementPosting/StatementUpdateLineAmount',
    StatementPostingReport:'/api/StatementPosting/StatementPostingReport',

    // pos disount apis
    GetposPromotionData: '/pos/api/Promotion/GetposPromotionData',
    InsertposPromotionData: '/pos/api/Promotion/InsertposPromotionData',
    UpdateposPromotionData: '/pos/api/Promotion/UpdateposPromotionData',
    posFindItemData: '/api/Item/FindItem?name_or_no=',
    InsertposMultiBuyData: '/pos/api/Promotion/InsertposMultiBuyData',
    GetposMultiBuyData: '/pos/api/Promotion/GetposMultiBuyData',
    InsertposMixAndMatchData: '/pos/api/Promotion/InsertposMixAndMatchData',
    GetposMixAndMatchData: '/pos/api/Promotion/GetposMixAndMatchData',
    InsertposGiftCardData: '/pos/api/Promotion/InsertposGiftCardData',
    UpdateposGiftCardData: '/pos/api/Promotion/UpdateposGiftCardData',
    GetposGiftCardData: '/pos/api/Promotion/GetposGiftCardData',
    // end

    // todo link server
    getAllLinkServerOfStore: '/api/POSStore/getAllLinkServerOfStore',
    pos_get_link_server_status: '/api/POSStore/pos_get_link_server_status',
    CreateUpdateLinkServerOfStore: '/api/POSStore/CreateUpdateLinkServerOfStore',
    pos_get_database_name_link_server: '/api/POSStore/pos_get_database_name_link_server?store_code=',
    pos_get_database_table_name_link_server: '/api/POSStore/pos_get_database_table_name_link_server?store_code=',
    pos_get_table_stucture_and_primary_column_by_link_server: '/api/POSStore/pos_get_table_stucture_and_primary_column_by_link_server?store_code=',
    pos_push_table_by_link_server: '/api/POSStore/pos_push_table_by_link_server',
    pos_pull_table_by_link_server: '/api/POSStore/pos_pull_table_by_link_server',
    pos_get_jobs_detail: '/api/POSStore/pos_get_jobs_detail',
    pos_job_creation: '/api/POSStore/pos_job_creation',
    StopJobByUser: '/api/POSStore/StopJobByUser?job_code=',
    DeleteJobByUser: '/api/POSStore/DeleteJobByUser?job_code=',
    StartJobByUser: '/api/POSStore/StartJobByUser',

    // todo state and district apis
    GetStateName: '/pos/api/LocationStore/GetStateName',
    GetDistrictName: '/pos/api/LocationStore/GetDistrictName?state_code=',

    // todo pos cycle count
    GetCycleCountHeader: '/pos/api/CycleCount/GetCycleCountHeader',
    GetCycleCountList: '/pos/api/CycleCount/PosCycleCountHeaderGet',
    GetCycleCountListLines: '/pos/api/CycleCount/PosCycleCountLineGet?code=',
    ScanCycleCountItem: '/pos/api/CycleCount/ScanCycleCountItem',
    DeleteCycleCountItem: '/pos/api/CycleCount/DeleteCycleCountItem',
    GetItemInventoryList: '/pos/api/CycleCount/GetItemInventoryList?item_no=',
    CompleteCycleCountItem: '/pos/api/CycleCount/CompleteCycleCountItem',

    PosOrderSendToNavServer: '/pos/api/Sale/PosOrderSendToNavServer?store_code=',
// Reports
    sale_category_report: '/api/Reports/sale_category_report',
    sale_dashboard: '/api/Reports/sale_dashboard',
    customer_wise_report: '/api/Reports/customer_wise_report',
    sale_person_wise_report: '/api/Reports/sale_person_wise_report',
    detailed_sale_report: '/api/Reports/detailed_sale_report',
    inventory_report: '/api/Reports/pos_inventory_report',

    //todo pos Employee
    getEmployeeList: '/api/Employee/GetEmployee',
    InsertEmployeeList: '/api/Employee/InsertEmployee',
    deleteEmployeeList: '/api/Employee/DeleteEmployee?emp_code=',
    updateEmployeeList: '/api/Employee/UpdateEmployee',



  };

  constructor(private httpClient: HttpClient
  ) {}

  get getHTTPHeader(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  getHTTPHeaderAuth(token: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
  }

  // post data to server
  async Post(path: string, jsondata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, JSON.stringify(jsondata), headers).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'False',
            message: error.message
          })).catch(err => reject({condition: 'False', message: err.message}));
      });

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'False', message: e.message})
      });
    }
  }

  // get data to the server
  async Get(path: string): Promise<any> {
    try {
      path = this.globalurl + path;
      var headers = this.getHTTPHeader;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.get<any>(path, headers).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'False',
            message: error.message
          })).catch(err => reject({condition: 'False', message: err.message}));
      });
    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'False', message: e.message})
      });
    }
  }

  getImageSrc(url: string) {
    try {
      if (url.includes('imageNotFound.png')) {
        return url;
      } else {
        return this.globalurl + '/' + url;
      }
    } catch (e) {
      return '';
    }
  }

  // For formdata
  async PostFormData(path: string, formdata: any): Promise<any> {
    try {
      path = this.globalurl + path;
      return await new Promise<any>((resolve, reject) => {
        this.httpClient.post<any>(path, formdata).toPromise()
          .then(result => resolve(result), error => reject({
            condition: 'false',
            message: error.message
          })).catch(error => reject({
          condition: 'false',
          message: error.message
        }))
      })

    } catch (e) {
      return new Promise<any>((resolve) => {
        resolve({condition: 'false', message: e.message})
      })
    }
  }

  // post data to server and get two type of response
  Post_Data_GetFile(path: string, jsondata: any) {
    path = this.globalurl + path;
    const request = new HttpRequest('POST', path, jsondata, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  Get_Data_With_DownloadStatus_GetFile(path: string) {
    path = this.globalurl + path;
    const request = new HttpRequest('GET', path, {
      responseType: 'blob',
      reportProgress: true,
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
    return this.httpClient.request(request);
  }

  blobToString(b) {
    var urldata, x;
    urldata = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', urldata, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(urldata);
    return x.responseText;
  }

}
